import { FC } from 'react';
import { Layout, Link, Footer } from 'components';
import {
  HeaderSection,
  LearnMoreSection,
  BenefitsSection,
  ApplyReasonsSection,
  RequirementsSection,
  StepsSection,
  TestimonialsSection,
} from 'page-components/program';
import { BulletsSection } from 'components/BulletsLabeled';
import { color } from 'style';
import headerImage from 'assets/images/women-founders-and-makers-header.svg';
import metaImage from 'assets/images/wfm-meta.png';
import logoImage from 'assets/images/WFAM-logo.svg';
import { fbTrack } from 'utils';
import type { TestimonialProp } from 'page-components/program/TestimonialsSection';
import { MenuItemProps, MenuItemTypeEnum } from 'components/Menu';
import { UnavailableModalContent } from 'components/UnavailableModalContent';

const PROJECT_NAME = 'Women Founders and Makers';
const APPLY_LINK = 'https://form.typeform.com/to/gBkmViYy';
const INFO_PDF = 'https://100mentors.s3-eu-west-1.amazonaws.com/static-assets/equall/Women_Founders_and_Makers.pdf';
const FAQ_PDF = 'https://100mentors.s3-eu-west-1.amazonaws.com/static-assets/equall/Women_Founders_and_Makers-FAQs.pdf';

const onApplyFBTrack = () => fbTrack('track', 'Lead', { content_name: 'WOMEN_FOUNDERS_AND_MAKERS' });

const menuItems: MenuItemProps[] = [
  { label: 'Συχνές Ερωτήσεις', type: MenuItemTypeEnum.LINK, href: FAQ_PDF },
  {
    href: APPLY_LINK,
    unavailableContent: <UnavailableModalContent projectName={PROJECT_NAME} />,
    type: MenuItemTypeEnum.APPLY_BUTTON,
    onApply: onApplyFBTrack,
    unavailable: UnavailableModalContent.isUnavailable(),
  },
];

const requirements = [
  'Άνω των 18 ετών',
  'Από όλη την Ελλάδα',
  'Ίδρυση ή ανάπτυξη  επιχείρησης στους επόμενους 0-6 μήνες',
];

const benefits: BulletsSection[] = [
  {
    title: 'Επιμόρφωση',
    bullets: [
      'Εξειδικευμένη εκπαίδευση (training) διάρκειας 38 ωρών.',
      'Απόκτηση και εμβάθυνση γνώσεων και καλών πρακτικών στον κλάδο της επιχειρηματικότητας.',
      'Εκπαίδευση από καταξιωμένους εκπαιδευτικούς σε ειδικά διαμορφωμένες ενότητες. ',
    ],
  },
  {
    title: 'Mentoring και Ανάπτυξη Δεξιοτήτων',
    bullets: [
      'Διαδραστικά εργαστήρια δεξιοτήτων διάρκειας (6 ωρών)',
      'Εξοικείωση και χρήση εργαλειών Παραγωγικής Τεχνητής Νοημοσύνης ως ψηφιακούς βοηθούς για την ανάπτυξη δεξιοτήτων και την καθοδήγηση στη δημιουργία και την εξέλιξη μιας επιχείρησης.',
      'Εξατομικευμένη καθοδήγηση από έμπειρους μέντορες και δικτύωση με επαγγελματίες από την αγορά εργασίας με στόχο την ενδυνάμωση σε κάθε βήμα του προγράμματος.',
    ],
  },
];

const applyReasons = [
  'Θέλετε να δημιουργήσετε ή να εξελίξετε την επιχείρησή σας.',
  'Θα αναπτύξετε κάθετες και οριζόντιες δεξιότητες και θα λάβετε πιστοποίηση.',
  'Θα μάθετε πώς να παρουσιάζετε τον εαυτό σας, την ιδέα σας, το προϊόν/την υπηρεσία σας μπροστά σε διαφορετικά κοινά στόχους.',
  'Είναι εντελώς δωρεάν.',
  'Θα λάβετε γνώσεις και συμβουλές γύρω από την επιχειρηματικότητα.',
  'Θα συνδεθείτε με διακεκριμένους μέντορες που θα σας απαντήσουν σε στοχευμένες και εξελιγμένες ερωτήσεις.',
];

const testimonials: TestimonialProp[] = [
  {
    name: 'Υπατία, KEAR® Founder, Αθήνα',
    text: 'Πάνω από όλα για εμένα EQUALL σημαίνει EQ + U + ALL. Ή αλλιώς Ενσυναίσθηση, Ωριμότητα, Υπομονή, Επιμονή και Αισιοδοξία για τη ζωή για ΟΛΕΣ μας, χωρίς καμία διάκριση. Και με ένα εξαιρετικό team εισηγητών.',
  },
  {
    name: 'Εύη, επιχειρηματίας στον κλάδο εξαγωγής ελαιολάδου και επιτραπέζιας ελιάς, Χαλκιδική',
    text: 'Η συμμετοχή μου έδειξε ότι η καινοτομία είναι κάτι δυσεύρετο και δύσκολο να υλοποιηθεί, όμως εάν στηριχθεί με την ανάλογη γνώση και επιμόρφωση τότε μπορεί να κάνει θαύματα, γεννώντας και άλλες νέες ιδέες. Έτσι και εμείς στην εταιρεία μας δημιουργήσαμε ήδη ένα νέο δρόμο για τα προϊόντα μας με πολύ θετικά αποτελέσματα. Σας ευχαριστώ πολύ!',
  },
  {
    name: 'Άννα, Συμμετέχουσα του 1ου κύκλου, Καβάλα',
    text: 'Ήταν ωραίο που είχα την ευκαιρία να συναντήσω κι άλλες γυναίκες με τόση ενέργεια και διάθεση να πραγματοποιήσουν τα όνειρά τους. Το περιβάλλον μου έδωσε κι εμένα το θάρρος να κινητοποιηθώ!',
  },
];

const Page: FC = () => (
  <Layout
    logo={logoImage}
    meta={{
      title: PROJECT_NAME,
      description: 'Πρόγραμμα για τη στήριξη της γυναίκας επιχειρηματία.',
      image: metaImage,
    }}
    menuItems={menuItems}
  >
    <HeaderSection
      title={
        <div>
          Ονειρεύεστε να δημιουργήσετε ή να εξελίξετε τη δική σας επιχείρηση;
          <br />
          Το δωρεάν εκπαιδευτικό πρόγραμμα Women Founders and Makers δημιουργήθηκε για εσάς!
        </div>
      }
      applyLink={APPLY_LINK}
      onApply={onApplyFBTrack}
      disableApply={UnavailableModalContent.isUnavailable()}
      unavailableContent={<UnavailableModalContent projectName={PROJECT_NAME} />}
      image={headerImage}
      imageWidth={400}
      imageAlt="Απασχολημένη γυναίκα επιχειρηματίας"
    />
    <LearnMoreSection
      title={`Ανακαλύψτε περισσότερα για το ${PROJECT_NAME}.`}
      text={
        <span>
          Το πρόγραμμα απευθύνεται σε όλες τις γυναίκες που έχουν δημιουργήσει ή επιθυμούν να δημιουργήσουν τη δική τους
          επιχείρηση. Γυναίκες που θέλουν να δημιουργήσουν αξία, αλλά δεν ξέρουν πώς ή διστάζουν να προχωρήσουν στα
          επόμενα βήματα.
          <br />
          <br />
          Το πρόγραμμα αποτελείται από δύο σκέλη: α) <b>Επιμόρφωση</b> (training) συνολικής διάρκειας 38 ωρών, η οποία
          χωρίζεται σε 5 ενότητες, διαμορφωμένες από το Κέντρο Επιχειρηματικότητας AHEAD – Alba Hub for Entrepreneurship
          and Development του Alba Graduate Business School, The American College of Greece και β){' '}
          <b>Mentoring και Ανάπτυξη Δεξιοτήτων</b>, ειδικά σχεδιασμένο από την ομάδα των 100mentors για την ανάπτυξη των
          οριζόντιων δεξιοτήτων των συμμετεχουσών, την προσωποποιημένη καθοδήγηση και την εμβάθυνση των γνώσεών τους,
          μέσω διαδραστικών εργαστηρίων και της εφαρμογής 100mentors.AI, η οποία συνδυάζει την Παραγωγική Τεχνητή
          Νοημοσύνη με την Ανθρώπινη Νοημοσύνη.
          <br />
          <br />
          Κάθε θεματική ενότητα επιμόρφωσης συνδυάζεται με ειδικά καταρτισμένη κοινότητα μεντόρων, που πραγματοποιούν το
          σκέλος του mentoring, οι οποίοι πέρα από τις πολύτιμες εμπειρίες τους θα μοιραστούν συμβουλές καθοδήγησης, θα
          ενδυναμώσουν και θα βοηθήσουν τις συμμετέχουσες στην ανάπτυξη των οριζόντιων δεξιοτήτων (soft skills) τους.{' '}
          <b>
            Αναλυτικότερες πληροφορίες για την επιμόρφωση και το mentoring θα βρείτε{' '}
            <Link href={INFO_PDF} targetBlank>
              εδώ
            </Link>{' '}
          </b>
          .
          <br />
          <br />
          {(!UnavailableModalContent.isUnavailable() || UnavailableModalContent.isBeforeUnavailable()) &&
            'Οι δηλώσεις συμμετοχής για τον 5ο κύκλο ξεκινούν στις 9 Φεβρουαρίου 2024 και θα γίνονται δεκτές μέχρι και τις 25 Μαρτίου 2024.'}
          {UnavailableModalContent.isAfterUnavailable() &&
            'Οι δηλώσεις συμμετοχής για τον 5ο κύκλο ολοκληρώθηκαν στις 25 Μαρτίου 2024.'}
        </span>
      }
    />
    <RequirementsSection
      backgroundColor={color.hmPurple1}
      title="Προϋποθέσεις συμμετοχής"
      subtitle="Στο πρόγραμμα Women Founders and Makers μπορούν να συμμετέχουν όλες οι γυναίκες:"
      labels={requirements}
    />
    <BenefitsSection
      heading="Τι θα σας προσφέρει το πρόγραμμα Women Founders and Makers;"
      subHeading="Η ιδιαιτερότητα του Women Founders and Makers είναι ότι συνδυάζει τη θεωρητική επιμόρφωση με το mentoring,
          έτσι ώστε κάθε συμμετέχουσα να λάβει όλα τα απαραίτητα εφόδια για να δημιουργήσει ή να εξελίξει την επιχείρησή
          της."
      bullets={benefits}
      footer={
        <p>
          Αναλυτικότερες πληροφορίες για την επιμόρφωση, το mentoring και την ανάπτυξη δεξιοτήτων θα βρείτε{' '}
          <Link href={INFO_PDF} targetBlank>
            εδώ
          </Link>
          .
        </p>
      }
      isListLabeled
    />
    <ApplyReasonsSection
      backgroundColor={color.hmPurple1}
      heading="Γιατί να δηλώσετε συμμετοχή στο πρόγραμμα Women Founders and Makers;"
      bullets={applyReasons}
      applyLink={APPLY_LINK}
      disableApply={UnavailableModalContent.isUnavailable()}
      unavailableContent={<UnavailableModalContent projectName={PROJECT_NAME} />}
      onApply={onApplyFBTrack}
    />
    <StepsSection projectName={PROJECT_NAME} title="Ποια είναι τα στάδια του προγράμματος;" faqLink={FAQ_PDF} />
    <TestimonialsSection
      backgroundColor={color.hmPurple1}
      title="Έχουν πει για τις δράσεις του προγράμματος πρόγραμμα EQUALL…"
      testimonials={testimonials}
    />
    <Footer
      signatures={[
        { title: 'Στρατηγικοί Συνεργάτες', logos: ['piraeus', '100mentors'] },
        { title: 'Συντελεστής Εκπαίδευσης', logos: ['deree'] },
      ]}
    />
  </Layout>
);

export default Page;
